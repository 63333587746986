.continue-buttons {
  #bottom-viewcart-buttons & {
    @include breakpoint($bp--medium-up) {
      padding-#{$rdirection}: 10%;
      text-align: #{$rdirection};
    }
  }
}

.panel {
  .checkout &.need-help-panel,
  .checkout &.links-panel {
    @include breakpoint($bp--large-up) {
      background: $color--gray--white;
      color: $color--black;
      a,
      a:visited {
        color: $color--black;
      }
      a:hover {
        color: $color--black;
        border-bottom-color: $color--black;
      }
    }
  }
}
