.profile-info__fieldset {
  .picker-radio {
    float: left;
    width: auto;
  }
  .gender_col,
  .sms_col,
  .birthday_col,
  .country_container {
    label {
      display: block;
    }
  }
  div.birthday_col {
    .select2-container {
      width: 100%;
      float: left;
      margin-right: 25px;
    }
  }
  .gender_col {
    margin-top: 15px;
    label {
      padding-bottom: 15px;
    }
    .picker {
      margin: 0 1em 1em 0;
    }
  }
}

.profile-info.birthday-fields {
  .profile-info__fieldset {
    .field-container--grid {
      .form-item:nth-of-type(2n + 1) {
        width: 33.33%;
        float: left;
        padding-right: 0;
        clear: right;
        margin-right: 0;
      }
      .form-item:nth-of-type(2n + 2) {
        width: 33.33%;
        float: left;
        padding: 0 1em;
      }
      .form-item.gender {
        width: 50%;
        padding-left: 0;
      }
    }
  }
}

.newsletter-info {
  .newsletter-info {
    &__fieldset {
      .picker-label {
        width: 82%;
      }
    }
  }
}

.payment-info {
  &__other .no-payment-text,
  &__other &__header {
    display: none;
  }
  &.panel {
    display: none;
  }
}

.order-details-page {
  .cart-item {
    &__desc {
      margin-left: 18% !important;
    }
    &__status {
      padding-left: 4%;
    }
    &__price {
      margin-left: 50% !important;
      .cart-item {
        &__product-price--sale {
          color: $color-text-red;
        }
      }
    }
    &__qty {
      margin-left: 72% !important;
    }
    .product-info {
      &__spp-status {
        .temp_out_of_stock_msg {
          display: none;
        }
      }
    }
  }
  .product-header {
    .price {
      margin-left: 50%;
    }
    .quantity {
      margin-left: 72%;
    }
  }
}

.ship_type_container {
  margin-top: 10px;
}

#address-form-popover {
  .default_ship_container {
    margin-bottom: 15px;
  }
}

.payment-item {
  .js-delete-address {
    display: none;
  }
}

.past-purchases-page {
  .past-purchases {
    .product__footer {
      a.notify_me {
        margin: 7px 0;
      }
    }
  }
}

.site-container {
  .past-purchases-page {
    .past-purchases {
      .grid--mpp {
        .product__footer {
          .product__add-to-bag {
            display: block;
          }
        }
      }
    }
  }
  .favorites-page {
    .favorites-board__list {
      .grid--mpp {
        .product__footer {
          .product__add-to-bag {
            display: block;
          }
        }
      }
    }
  }
  .account-page__panel {
    .past-purchases {
      .grid--mpp {
        .product__footer {
          .product__add-to-bag {
            display: block;
          }
        }
      }
    }
  }
}

.order-timeline {
  ul {
    display: flex;
  }
}

.order-timeline__step {
  text-align: center;
  position: relative;
  pointer-events: none;
  opacity: 0.3;
  flex: 1;
  + .order-timeline__step {
    margin-left: 25px;
    &:before {
      content: '';
      border-top: 1px solid $color--black;
      width: 100%;
      position: absolute;
      top: 10px;
      transform: translate(calc(-100% - 13px));
    }
  }
  + .order-timeline__step_large {
    &:before {
      width: calc(100% - 18px);
    }
    + .order-timeline__step {
      &:before {
        width: calc(100% + 20px);
      }
    }
  }
  @include breakpoint($bp--small-up) {
    + .order-timeline__step_large {
      &:before {
        width: 100%;
      }
      + .order-timeline__step {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

.order-timeline__step_active {
  opacity: 1;
  pointer-events: auto;
}

.order-timeline__step.order-timeline__step_large {
  .order-timeline__step-title {
    max-width: none;
  }
}

.order-timeline__step-bullet {
  background-color: $color--black;
  border-radius: 100%;
  font-size: 14px;
  color: $color--white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  width: 25px;
  height: 25px;
}

.order-timeline__step_cancel .order-timeline__step-bullet {
  background-color: $color--dark-red;
}

.order-timeline__step-title {
  font-size: 14px;
  max-width: 60px;
  margin: auto;
}

.order-timeline__step-subtitle {
  font-family: inherit;
  font-size: 10px;
}

.sign-in-container .new-account {
  .password-criteria {
    text-transform: uppercase;
    font-size: 14px;
    color: $color--gray-warning;
    p {
      margin-bottom: 3px;
    }
    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}

.past-purchases {
  .product__footer {
    .product-item {
      &__out-of-stock {
        height: auto;
        float: right;
        line-height: 0;
        display: inline-flex;
        position: inherit;
        padding: 20px 0;
      }
    }
  }
  .product--teaser {
    .product__images {
      bottom: 69px;
    }
  }
}
