//  @file br_pc.scss
//  \brief Pull all parts of the BR-PC theme together into one file
$mac-lover: true;
$loyalty_v2: true;
//loyalty font-families
$loyalty-tstar-font: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
$loyalty-ano-bold-font: 'ano-bold_regular', Helvetica, Arial, sans-serif;

@import 'overrides/overrides';

@import '../../mac_base/scss/mac-base';

// Live engage syltes
@import 'base/common';
@import 'base/cms';
@import 'sections/_gwp_pc';

// SMACSS/sections/contact-us
@import 'sections/pc/contact-us/contact-us';

// SMACSS/sections/account
@import 'sections/pc/account/account';

// SMACSS/Components/checkout
@import 'sections/pc/checkout/checkout';

// Social Login
@import 'sections/_social_login';
@import 'sections/pc/_power_review';

//Store Locator
@import 'sections/pc/_all';
@import 'components/_site-header';
@import 'components/opc_page-checkout';

@import 'sections/loyalty/_loyalty_all';

// One Trust Cookie Styles.
@import '../../mac_base/scss/onetrust_cookie/cookie_settings_ot';

// Appointment Booking Styles
@import 'sections/pc/appointment_booking';

// Cart Updates
@import 'sections/_enhanced_cart_page';