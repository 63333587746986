.site-header {
  .site-navigation {
    @include breakpoint($bp--xxlarge-up) {
      width: 96%;
    }
  }
  li.site-navigation__item,
  li.site-navigation__more {
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 0 16px);
    }
  }
  .site-header__prefix {
    @include breakpoint($width-xxlarge) {
      width: 121px;
      margin-#{$ldirection}: 12px;
    }
    .site-logo {
      margin-#{$ldirection}: 2px;
      .site-logo__image {
        @include breakpoint($width-xxlarge) {
          margin-#{$ldirection}: 0px !important;
        }
      }
    }
  }
  .site-header__menu {
    @include breakpoint($width-xxlarge) {
      width: 864px;
      margin-#{$ldirection}: 172px;
    }
    .site-header__extra {
      @include breakpoint($width-xxlarge) {
        float: right;
      }
    }
  }
  .site-header__suffix {
    @include breakpoint($width-xxlarge) {
      width: 250px;
    }
    .site-header__tools {
      .site-email-signup {
        @include breakpoint($width-xxlarge) {
          padding: 0 14px;
          margin: 0;
          min-width: 0;
        }
      }
      .block-template-site-my-mac-v1 {
        @include breakpoint($width-xxlarge) {
          width: 43%;
        }
      }
      .block-template-site-my-shades-v1 {
        @include breakpoint($width-xxlarge) {
          width: 38px;
        }
      }
    }
  }
}
