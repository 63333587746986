.product-full--enhanced {
  .product-full__price-details {
    .product-price-installment {
      font-size: 12px;
    }
  }
}

.site-footer {
  .site-footer--sticky {
    .mac-select-loyalty-cols {
      .mac-select-loyalty-summary {
        &__header,
        &__content {
          text-align: center;
        }
      }
    }
    .site-email-signup {
      display: block !important;
      visibility: visible !important;
      .site-email-signup__link {
        display: none;
      }
    }
    .site-email-signup__contents-header {
      .site-email-signup__contents-close {
        display: none;
      }
    }
    .site-email-signup__contents-title {
      a {
        color: $color--white;
        margin-top: 0.3em;
        margin-bottom: 0.1em;
        font-weight: 400;
        text-transform: uppercase;
        font-family: $ano-bold_regular-font;
        @if $fonts_update == true {
          font-size: 20px;
        } @else {
          font-size: 28px;
        }
      }
    }
    .site-email-signup__contents-form {
      .field-container {
        margin-bottom: 65px;
        .intro {
          p {
            text-align: center;
            display: inline-block;
          }
        }
        .return-user-email-newsletter {
          width: 350px;
          height: 40px;
          float: left;
          display: inline-block;
          margin-left: 20px;
        }
        .form-signup-pc-email-promotions {
          position: absolute;
          margin-top: 50px;
          .picker-element {
            top: 2em;
          }
          .picker-checkbox {
            .picker-handle {
              background-color: $color--white;
              top: 2em;
            }
            .picker-label {
              color: $color--white;
              text-transform: initial;
              a {
                color: $color--white;
              }
            }
          }
        }
        .form-signup-sms-promotions {
          display: none;
        }
        .site-email-signup__contents-submit {
          background: $color--white;
          color: $color--black;
          border-bottom: none;
          height: 45px;
          line-height: inherit;
        }
        .form-item {
          margin-bottom: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1370px) {
  .site-footer {
    .site-footer--sticky {
      .site-email-signup__contents-form {
        .field-container {
          .form-signup-pc-email-promotions {
            margin-top: 90px;
          }
          .site-email-signup__contents-submit {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@if $fonts_update == true {
  .checkout__sidebar,
  .viewcart-samples-overlay {
    #favorites-panel {
      @include breakpoint($bp--medium-up) {
        .add-to-cart {
          .btn {
            font-size: 10px;
            letter-spacing: -1px;
          }
        }
      }
    }
  }
  .checkout .checkout__sidebar {
    .product__desc .add-to-cart {
      .btn {
        font-size: 10px;
        letter-spacing: -1px;
      }
    }
  }
}

.cr21-refresh.site-header-formatter {
  .site-header-formatter__bar {
    .site-header-formatter__utilities--loyalty {
      @include breakpoint($bp--largest-up) {
        display: none;
      }
    }
  }
}

.content {
  .site-my-mac {
    .site-my-mac__link {
      margin: 1px;
    }
  }
}
