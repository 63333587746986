$border-color: #bbbbbb;

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $border-color;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
  }
  .address_lookup_wrapper li {
    padding-bottom: 1em;
  }
}

.site-container {
  .grid--mpp {
    .product--teaser {
      .product__footer {
        .product__add-to-bag {
          display: none;
        }
        .product__price_ppu {
          width: 51%;
        }
        .product__inventory-status {
          .temp-outof-stock_text,
          .coming-soon_text {
            display: none;
          }
          a.notify_me {
            @include body-text--small-bold;
            display: flex;
            align-items: center;
            padding: 0 20px;
            justify-content: center;
            border-radius: 50px;
            height: 45px;
          }
        }
      }
      &.prod_inv_status-2 {
        .product__footer {
          .product__inv-status-item.temp-out-of-stock {
            padding: 0;
            @include breakpoint($bp--medium-down) {
              padding: 15px 0;
              .temp-outof-stock_text {
                margin-top: 30px;
              }
              .notify_me {
                top: 0px;
              }
            }
          }
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
    .favorites-board__item {
      .product__footer {
        .notify_me {
          @include swap_direction(margin, 0);
        }
        .product-item__out-of-stock {
          text-align: right;
        }
      }
    }
    .prod_inv_status-2,
    .prod_inv_status-3,
    .prod_inv_status-7 {
      .product__footer {
        .product__link-to-spp_single_shade,
        .product__link-to-spp {
          display: none !important;
        }
      }
    }
  }
  .collection-detail-formatter {
    .grid--mpp {
      .prod_inv_status-2,
      .prod_inv_status-3 {
        .product__footer {
          a.notify_me {
            width: auto;
            line-height: 30px;
            height: 30px;
            top: auto;
          }
        }
      }
      .grid--mpp__item {
        .product--teaser.product--shaded.product--sku-product {
          .product__link-to-spp {
            display: none;
          }
          &.prod_inv_status-2 {
            .product__footer {
              .product__inventory-status {
                .temp-out-of-stock {
                  display: block;
                }
              }
            }
          }
          &.prod_inv_status-3 {
            .product__footer {
              .product__inventory-status {
                .coming-soon {
                  display: block;
                }
              }
            }
          }
        }
        .prod_inv_status-3 {
          &.product--single-not-orderable {
            .product__inventory-status {
              .coming-soon {
                display: block;
                background-color: $color--gray--light;
              }
            }
          }
        }
      }
    }
  }
  .page--spp__product {
    .product__footer {
      a.notify_me {
        padding: 0px 20px;
        width: 100%;
        position: static;
        font-size: 1.5em;
        height: 60px;
        line-height: 64px;
      }
      .product__share-wrapper {
        .product__social-links {
          width: 7.5em;
        }
      }
    }
    .temp-outof-stock_text {
      display: none;
    }
  }
  .layout--artistry-artist {
    .shades {
      .product--shaded,
      .product--teaser {
        .product__footer {
          float: #{$rdirection};
          position: relative;
          .product__price {
            float: none;
            line-height: 31px;
          }
          a.notify_me {
            @include swap_direction(margin, 0 0 10px 0);
            width: 100%;
            height: 30px;
            line-height: 20px;
            position: relative;
          }
          .product__price_ppu {
            width: 100%;
            top: 0;
            position: relative;
          }
          a.product__add-to-bag {
            width: 152px;
            @include swap_direction(padding, 0);
            @include swap_direction(margin, 10px 0 0 0);
          }
        }
      }
    }
    .artist--faves {
      .artist--products__carousel-slide {
        .product--teaser {
          .product__header {
            height: 100px;
            padding-bottom: 20px;
          }
          .product__images {
            position: relative;
            height: 310px;
            margin-bottom: 0;
            bottom: 20px;
          }
          .product__footer {
            position: relative;
            a.notify_me {
              float: #{$rdirection};
              @include swap_direction(margin, 0 0 10px 0);
              height: 29px;
              @include swap_direction(padding, 0);
              line-height: 30px;
              position: relative;
              width: 90px;
              //A common width is not given as it has to be set equal to the AddToCart button of each site, so width is set in region specific SCSS.
            }
            .temp-outof-stock_text {
              float: #{$rdirection};
              display: none;
            }
            .product__price_ppu {
              width: 100%;
            }
            .product__inventory-status {
              li.temp-out-of-stock {
                @include swap_direction(padding, 0);
              }
              li.coming-soon {
                @include swap_direction(padding, 0 0 0 15px);
              }
            }
            .product__link-to-spp {
              #{$rdirection}: 0;
              position: relative;
            }
            .product_content_fav_mobile {
              .product__add-to-bag {
                @include swap_direction(padding, 0 15px);
                font-size: 15px;
              }
            }
          }
        }
        .product--not-shoppable {
          .product__shade-column {
            .shade-picker__trigger {
              bottom: 110px;
            }
          }
        }
      }
      .artist--products {
        .artist--products__carousel-slide {
          height: auto !important;
        }
      }
    }
  }
  .content {
    .responsive-container {
      .sec-nav__title {
        width: auto;
        padding-#{$rdirection}: 0;
      }
    }
    .mpp-responsive-container {
      .product-brief__inventory-status {
        .product-inventory-status__temp-oos-text {
          .notify-status {
            max-width: 190px;
            text-align: center;
          }
        }
      }
    }
  }
  .search-filter__container {
    .search-dimension__header.results-header {
      margin: 0 0 0.25em;
      border: medium none;
    }
  }
  .artistry-video-player-wrapper {
    .product__footer {
      .product__price_ppu {
        display: none;
      }
    }
  }
  .artistry--artist {
    .product__footer {
      .product__inventory-status {
        .temp-outof-stock_text {
          display: none;
        }
      }
    }
  }
  .artistry--artist {
    .prod_inv_status-2 {
      .product__footer {
        .temp-out-of-stock__text {
          bottom: 93%;
        }
        .product__installment_price {
          .product__add-to-faves,
          .product__price {
            @include swap_direction(margin, 14px 0 0 0);
          }
        }
        .product__add-to-faves,
        .product__price {
          @include swap_direction(margin, 14px 0 0 0);
        }
      }
    }
    .prod_inv_status-3 {
      .product__footer {
        .coming-soon-text {
          height: 30px;
          line-height: 30px;
          top: 70px;
          #{$ldirection}: 0;
          margin: 18px 0 15px;
        }
        .product__installment_price {
          .product__add-to-faves,
          .product__price {
            margin: 14px 0 0 0;
          }
        }
        .product__add-to-faves,
        .product__price {
          margin: 14px 0 0 0;
        }
      }
    }
    .prod_inv_status-2,
    .prod_inv_status-3,
    .prod_inv_status-7 {
      .product__link-to-spp_single_shade {
        display: none;
      }
    }
  }
  #product-page-livechat-hitarea {
    width: 100px;
    bottom: 55px;
  }
  .artistry-video-player__products {
    .prod_inv_status-2 {
      .product__footer {
        .temp-out-of-stock-short__text,
        .coming-soon_text {
          bottom: 95%;
        }
      }
    }
    .product--teaser {
      .product__detail {
        .product__footer {
          a.product__add-to-faves {
            @include swap_direction(margin, 8px 0 0 5px);
            float: right;
            position: static;
            right: auto;
            top: auto;
            line-height: 1;
          }
        }
      }
    }
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 13.66667%;
    }
    &:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 37.33333%;
    }
    &:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 55%;
    }
    &:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 67.66667%;
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  @if $fonts_update == true {
    margin: 10px 7px 5px 7px;
    height: 200px;
  } @else {
    margin: 17px 7px 5px 7px;
    height: 190px;
  }
}

.tiny-waitlist-overlay {
  .waitlist_header {
    @if $fonts_update == true {
      font-size: 17px;
    } @else {
      font-size: 20px;
    }
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
  .field {
    .email_input {
      float: left;
      @if $fonts_update == true {
        width: 75%;
      } @else {
        width: 79%;
      }
    }
    .align-r {
      float: left;
      margin-left: 10px;
      .input-btn {
        height: 58px;
      }
    }
    .terms_condition_cont {
      float: right;
      margin: 10px 5px 0 0;
    }
  }
}

.contact-us-page {
  .contact-nav {
    li:last-child {
      display: none;
    }
  }
}

.tout-block-landscape__body {
  padding-top: 10px;
  p {
    margin-bottom: 0.5em;
  }
}

#email_sms_signup {
  #form--email_sms_signup--field--MOBILE_NUMBER {
    display: none;
  }
}

.site-header__tools {
  .block-template-site-email-signup-ca-v1 {
    display: inline-block;
  }
}

.site-footer--bottom {
  .footer_legacy_text {
    font-size: 12px;
    text-align: center;
  }
}

.viewcart {
  .checkout-header {
    &__title {
      text-align: center;
      width: 100%;
      padding-right: 7.5%;
      right: 0;
    }
  }
}

.section-artistry-all-artists {
  .artistry-artists-page-formatter {
    .artistry-artists-page__content {
      display: inline-block;
      width: 100%;
    }
  }
}

.section-artistry {
  .artistry-landing-page-formatter {
    display: inline-block;
    width: 100%;
  }
}

#prechat_container {
  .prechat-form-container {
    .liveperson-btn-container {
      margin-top: 10px;
    }
  }
}

#email_sms_signup,
.site-email-signup__contents {
  #form--email_sms_signup--field--MOBILE_NUMBER {
    display: none;
  }
}

.form--newsletter__form-items {
  .form-type-checkbox {
    .picker-label {
      p {
        line-height: 20px;
      }
    }
  }
}

.section-collections-mac-chris-chang {
  .product__footer {
    .product_content_fav_mobile {
      .product__inventory-status {
        li {
          padding: 0;
          margin: 0;
          a.notify_me {
            position: static;
            padding: 5px 15px;
            right: 0;
            width: 100%;
            font-size: 1.5em;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

.page--spp__custom_palette.MAC_6_PALETTE {
  .product__detail-zone {
    height: 31%;
    padding: 0;
    width: 33%;
  }
  .product__detail-zones {
    padding: 32% 21% 0;
  }
}

.section-culture {
  .culture-detail__article {
    .culture-detail__article-content__meta-share:hover {
      > .culture__share_text {
        visibility: hidden;
      }
    }
    .culture-detail__article-content__meta-share {
      > .culture__share_text {
        visibility: visible;
      }
    }
  }
}

.grid--mpp {
  .product__detail {
    .product__price_ppu {
      display: none;
    }
  }
  .grid--mpp__item {
    .prod_inv_status-3 {
      &.product--single-not-orderable {
        .product__inventory-status {
          .coming-soon {
            display: block;
          }
        }
      }
    }
  }
}

.grid--mpp {
  .product--teaser {
    .product__link-to-spp_single_shade {
      @include body-text--small-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      height: 45px;
      border-radius: 50px;
      width: auto;
      clear: none;
      text-transform: uppercase;
      text-align: center;
      float: $rdirection;
      @include swap_direction(padding, 0 15px);
      cursor: pointer;
      background-color: black;
      font-family: $ano-bold_regular-font;
    }
    .product__link-to-spp_single_shade:hover {
      text-decoration: underline;
    }
  }
  .product--teaser.product--shaded {
    .product__link-to-spp_single_shade {
      display: none;
    }
  }
  .product.hide__shop__shade .product__add-to-bag {
    display: none !important;
  }
}

.product__link-to-spp_single_shade {
  .collection-quickshop & {
    display: none;
  }
  .product--full & {
    display: none;
  }
}

.artist--products__carousel {
  .product--teaser {
    .product__footer {
      .product__link-to-spp_single_shade {
        display: none;
      }
    }
  }
}

.grid--mpp {
  .grid--mpp__item,
  .artist--products__carousel {
    .product__footer {
      .product__price-installment {
        #{$ldirection}: 0;
      }
    }
  }
}

.tout-block-landscape {
  .tout-block-landscape__caption {
    .tout-block-landscape__headline {
      margin-top: 2.5em;
    }
  }
}

.mac-select {
  &__marketing-page {
    .mac-select__marketing-page {
      &__benefits__section {
        &-title {
          h3 {
            line-height: 1.1;
          }
        }
        ul {
          li {
            width: auto;
            .benefits {
              width: 60%;
            }
            .special-offer {
              width: 75%;
              padding: 0 10px;
            }
          }
        }
      }
      &__title {
        font-size: 28px;
        line-height: 1.5;
      }
      &__message {
        font-size: 18px;
      }
    }
  }
  &-loyalty-tier {
    h3 {
      &.mac-select-loyalty-summary {
        &__header {
          margin-top: inherit !important;
        }
      }
    }
  }
}
.select-marketing-page,
.loyalty,
.loyalty__content-bottom {
  h2,
  h3,
  h4,
  h6,
  .btn,
  .btn--full {
    font-family: $ano-bold_regular-font;
  }
  h2 {
    font-size: 25px;
  }
  h3,
  h4 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
}
.site-container {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          float: right;
          padding: 0;
        }
      }
    }
  }
}

.grid--mpp {
  .product__footer {
    .product_content_fav_mobile {
      width: 100%;
    }
  }
}

.site-header__container {
  .site-navigation {
    .site-navigation__item {
      .site-navigation__dropdown {
        .site-navigation__menu {
          .site-navigation__submenu-col {
            h3 {
              margin-top: 0px !important;
            }
          }
        }
      }
    }
  }
}
