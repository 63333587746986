.viewcart {
  .donation_amounts {
    .donation_amount {
      display: inline-block;
    }
  }
  .continue-buttons {
    text-align: right;
    width: 100%;
    float: left;
    margin-right: -100%;
    margin-left: 0;
    clear: none;
    .continue-shopping {
      float: left;
      @include swap_direction(margin, 18px 0);
    }
  }
  .checkout__content {
    .order-summary-sidebar {
      display: none;
    }
  }
}

.ship-type {
  .picker-radio {
    float: left;
  }
  label {
    display: block;
  }
}

.panel {
  .valid_marker {
    font-size: 0em;
  }
  .payment-container {
    .sub-section {
      clear: both;
    }
  }
}

.signin-panel,
#return-user {
  .js-forgot-pw-note {
    margin-bottom: 15px;
  }
}

#confirmation-panel {
  .confirmation-panel__print-buttons {
    margin-bottom: 10px;
  }
  .checkout-header {
    .confirmation-panel__title {
      padding-#{$ldirection}: 0;
    }
  }
}

#mobile-breadcrumb {
  display: none;
}

.invoice_container {
  position: relative;
  padding: 20px 20px 0 10px;
  .menu-item-blocks {
    display: none;
  }
  .messages.error {
    background-color: #ffffff;
    color: #b84947;
    border-bottom: 0px;
  }
  .invoice_wrapper {
    float: left;
    width: 50%;
  }
  .form-container {
    .form-item {
      margin: 0.5em 0.5em 0.5em 0;
      display: inline-block;
      width: 48%;
    }
  }
}

.checkout {
  #order-summary-panel {
    .order-summary__shipping {
      clear: both;
    }
    .order-summary__shipping-content {
      #checkout_shipmethod {
        float: right;
        margin-right: 8%;
        .ship-method {
          margin: 10px 40px 0 5px;
          width: 100%;
        }
        label {
          display: inline-block !important;
        }
      }
      .cpf-field {
        float: right;
        width: 100px;
        .viewcart-cpf-field {
          border-bottom: none;
          .postcode-lookup {
            input[type='text'] {
              padding: 5px;
            }
          }
        }
      }
      .order-summary__shipping-method {
        float: right;
        margin: 17px 20px 0 0;
      }
      .field-container {
        .form-item {
          .ship-method {
            width: auto;
            label.order-summary-panel-label {
              display: block;
              float: left;
              margin-right: 7px;
            }
            a.edit {
              text-decoration: underline;
              border-bottom: 0;
              float: left;
            }
          }
        }
      }
    }
    .order-summary__stock_items_only {
      display: none;
    }
  }
  .checkout__content {
    .sign-in-panel {
      .btn-primary {
        border: none;
        line-height: 58px;
      }
    }
    .order-summary__content {
      .value {
        width: 10.33333%;
        margin-#{$ldirection}: 81.33333%;
      }
    }
  }
  .shipping-panel {
    .shipping-edit-content {
      .continue-button-wrapper {
        .btn-primary {
          line-height: 58px;
        }
      }
    }
  }
  .email-and-sms-promotions {
    .email_promotions {
      .picker {
        @include swap_direction(margin, 21px 0);
      }
    }
  }
  .top-viewcart-buttons {
    border-bottom: 1px solid $color--gray--lightest;
    .viewcart-buttons-panel {
      @include breakpoint($bp--large-up) {
        width: 72.5%;
      }
    }
  }
  .checkout__sidebar {
    .product__desc {
      .add-to-cart {
        .btn {
          font-size: 12px;
          padding: 0 15px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
    .offer-code-panel {
      .offer-code__one-offer-only {
        display: none;
      }
      .offer-code__submit {
        margin-bottom: 5px;
      }
    }
    #order-summary-panel {
      .order-summary__shipping-content {
        .order-summary__shipping-method {
          margin-right: 15px;
        }
        #checkout_shipmethod {
          margin-right: 0;
          float: none;
          .ship-method {
            margin: 0px 49px 0px 0px;
          }
        }
      }
    }
    .order-summary-content {
      display: none;
    }
    #viewcart-panel {
      .product-list {
        .cart-item {
          .offer-item__qty {
            text-align: $ldirection;
          }
        }
      }
    }
  }
  .shopping-bag-panel {
    &__item-count {
      @include swap_direction(padding, 0.2em 0 0 0);
    }
  }
  #viewcart-panel {
    .offer-item__qty {
      text-align: center;
    }
    .cart-item__price {
      .cart-item {
        &__product-price--non-sale {
          text-decoration: line-through;
        }
        &__product-price--sale {
          color: $black;
        }
      }
    }
  }
}

.shipping-edit-content {
  #continue-btn {
    clear: both;
  }
  #checkout_gift_option_container {
    display: block;
  }
  .gift-options__content {
    float: left;
    display: none;
  }
  .address-form {
    .field-container {
      select {
        text-decoration: none;
      }
    }
  }
}

#checkout_billing {
  .accepted_privacy {
    width: 785px;
    height: 200px;
    overflow: auto;
    border: 1px solid $border-color;
    padding: 15px;
  }
}

.city-state-zip {
  .state-cointainer {
    .select2-container {
      display: none;
    }
    select {
      display: block !important;
      height: 58px;
    }
  }
}

.checkout__content {
  position: relative;
  .payment-edit-content {
    .payment-type {
      .payment-container {
        .payment-form {
          .payment-form__pp_cash {
            .cash_type_field {
              display: none;
            }
          }
        }
      }
    }
  }
  #checkout_shipping_panel {
    #form--address_shipping--field--STATE {
      padding-left: 12px;
    }
  }
  #checkout_billing {
    .related-media.cc {
      margin-left: 15px;
    }
  }
  .shipping-panel {
    &.finished.review-view {
      width: 100%;
      float: left;
    }
    #shipping-panel-edit {
      background: none;
      padding: 0;
    }
  }
  .payment-panel {
    #payment-panel-edit {
      background: none;
      padding: 0;
    }
  }
  .payment-panel.finished.review-view {
    position: absolute;
    padding-bottom: 83px;
    width: 50%;
    right: 0;
    border: none;
    #payment-display {
      .address.block {
        margin-bottom: 1em;
      }
    }
  }
  .shipping-panel {
    .shipping-address-display {
      margin-bottom: 2em;
      &__addresses {
        margin-bottom: 1em;
      }
    }
    .gift-options-display {
      p {
        margin-bottom: 1em;
      }
    }
  }
  .review-panel {
    .related-media.cc {
      margin-left: 10px;
    }
    #adyen-payment #adyen-payment-form {
      margin-top: 10px;
      .chckt-pm-card .chckt-pm__details {
        .chckt-form-label {
          &--exp-date {
            width: 40%;
          }
          &--cvc {
            width: 55%;
          }
        }
      }
    }
    .chckt-button {
      &:not(.chckt-button--disabled) {
        background-color: $color--black;
      }
    }
  }
}
.kit-container {
  @include swap_direction(margin, 0 85px 0 17%);
  padding: 90px 0 0 0;
  .checkout__sidebar & {
    margin: 0;
    padding: 0;
    clear: both;
  }
  .cart-item {
    &__desc-info {
      margin: 0 0 20px 0;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #{$color--black};
      &:last-child {
        margin: 0;
        padding: 0;
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      margin: 0;
    }
  }
}
a.bto_gerar_boleto {
  color: $color--white;
  &:visited {
    color: $color--link-grey;
  }
}