.content {
  .submenu--sticky {
    .submenu__content {
      .submenu__nav {
        li:last-child {
          display: none;
        }
      }
    }
  }
  .submenu {
    &.submenu--sticky {
      &.appointment-nav {
        &.responsive-container {
          position: fixed;
        }
      }
    }
  }
}

.sd-appt-booking {
  .elc-appt-booking_mobile-store-selector {
    .elc-button {
      width: fit-content;
    }
  }
}

.appt-book {
  .my-appointments {
    .appointment-container {
      .appointment-details {
        & > div.date-time {
          text-transform: none;
        }
      }
    }
  }
}
